import React, { useEffect } from "react";
import { useForm, FieldValues, Control } from "react-hook-form";
import { Value } from "react-hook-schema-form";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { Field, Schema } from "schemaComponents";
import Button from "react-bootstrap/Button";
import { Alert, Stack } from "react-bootstrap";
import { useConfirm } from "hooks/dialogs";

import "./SchemaForm.scss";
import { useCopyPaste } from "hooks/copyPaste";
import { useSearchParamAccessor } from "utils/url";
import { useFieldAccessor } from "hooks/accessor";
import { useFormatter } from "hooks/intl";

export type SchemaFormParameter = {
  title?: string;
  subtitle?: string;
  confirmMessage?: string | false; // deprecated
  schema: Schema;
  enableFormAlert?: boolean;
  preview?: {
    title: string;
    component: React.ComponentType<{ control: Control }>;
    isDisabled?: boolean;
  };
  edit?: {
    title?: string;
    confirm?: string | false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handler?: (values: any) => Promise<undefined | unknown> | undefined;
    path?: string | ((values: Value) => string);
    isDisabled?: boolean;
  };
  back?: {
    title?: string;
    confirm?: string | false;
    handler?: () => Promise<undefined | unknown> | undefined;
    path?: string;
  };
  remove?: {
    title?: string;
    confirm?: string | false;
    handler?: () => Promise<void | unknown> | undefined;
    path?: string;
  };
  backPath?: string;
  clipboard?: {
    objectType: string;
    ignoreKeys?: string[];
    retainKeys?: string[];
    isDisabled?: boolean;
  };
  header?: {
    isStacked?: boolean; // 既存仕様だとclipboardがあるか否かでstackするかの判定をしているが、改修により例外が存在するので、このフラグを追加
  };
  tooltip?: string;
};

const SchemaForm = ({
  data,
  readOnly,
  parameter,
  accessorRef,
}: {
  data: Value;
  parameter: SchemaFormParameter;
  readOnly?: boolean;
  accessorRef?: React.MutableRefObject<null | {
    get: () => Value;
    set: (value: Value) => void;
  }>;
}) => {
  const { handleSubmit, control, formState, reset, getValues } = useForm({
    defaultValues: data,
  });
  // useEffect(() => {
  //   reset(data);
  // }, [data, reset]);

  if (accessorRef) {
    accessorRef.current = {
      get: () => getValues(),
      set: (value) => reset(value),
    };
  }
  const navigate = useNavigate();
  const { back, edit, remove, schema, preview, enableFormAlert, backPath } =
    parameter;
  const hasErrors = enableFormAlert && Object.keys(formState.errors).length > 0;
  const PreviewComponent = preview?.component;
  const confirm = useConfirm();
  const { formatMessage } = useFormatter();
  const clickSubmit = async (value: FieldValues) => {
    if (edit?.confirm !== false) {
      const result = await confirm({
        message: edit?.confirm || "保存しますか？",
      });
      if (!result) return;
    }
    try {
      const record = await edit?.handler?.(value);
      const path =
        (typeof edit?.path === "function" ? edit.path(record) : edit?.path) ||
        backPath;
      path && navigate(path);
    } catch (e) {
      console.error(e);
    }
  };
  const clickDelete = async () => {
    if (remove?.confirm !== false) {
      const result = await confirm({
        message: remove?.confirm || "削除しますか？",
      });
      if (!result) return;
    }
    try {
      await remove?.handler?.();
      const path = remove?.path || backPath;
      path && navigate(path);
    } catch (e) {
      console.error(e);
    }
  };
  const clickBack = async () => {
    if (back?.confirm) {
      const result = await confirm({
        message: back?.confirm,
      });
      if (!result) return;
    }
    try {
      await back?.handler?.();
      const path = back?.path || backPath;
      path && navigate(path);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div className="tile">
        <div className="card tile">
          <div className="card-content">
            <form
              onSubmit={handleSubmit(clickSubmit)}
              className="adjust-width-expand"
              noValidate
            >
              <Field control={control} schema={schema} readOnly={readOnly} />
              {!readOnly && (
                <>
                  {(edit || remove || back || backPath) && <hr />}
                  {edit && hasErrors && (
                    <Alert variant="danger">
                      {formatMessage("Form.ErroMessage.NoInputs")}
                    </Alert>
                  )}
                  <div>
                    {edit && (
                      <Button type="submit" className="me-2">
                        {edit?.title || "保存"}
                      </Button>
                    )}
                    {remove && (
                      <Button
                        onClick={clickDelete}
                        type="button"
                        variant="danger"
                        className="me-2"
                      >
                        {remove.title || "削除"}
                      </Button>
                    )}
                    {(back || backPath) && (
                      <Button
                        onClick={clickBack}
                        type="button"
                        variant="outline-secondary"
                        className="me-2"
                      >
                        {back?.title || "もどる"}
                      </Button>
                    )}
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
        {!preview?.isDisabled && PreviewComponent && (
          <div className="card tile">
            <header className="card-header">
              <p className="card-header-title">{preview?.title}</p>
            </header>
            <div
              className="card-content preview-wrapper"
              style={{ padding: "0.5rem" }}
            >
              <PreviewComponent control={control}></PreviewComponent>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const SchemaFormPage = ({
  data,
  parameter,
  loading,
  readOnly,
  editModeEnabled,
  header,
}: {
  data: Value;
  parameter: SchemaFormParameter;
  loading?: boolean;
  readOnly?: boolean;
  editModeEnabled?: boolean;
  header?: JSX.Element;
}) => {
  const { title, subtitle } = parameter;
  const { copy, paste } = useCopyPaste({
    objectType: parameter.clipboard?.objectType,
    ignoreKeys: parameter.clipboard?.ignoreKeys,
  });
  const confirm = useConfirm();
  const accessorRef = React.useRef<{
    get: () => Value;
    set: (value: Value) => void;
  }>(null);

  const searchParamAccessor = useSearchParamAccessor<{
    edit: "1" | undefined;
  }>();
  const { value: editMode, setValue: setEditMode } = useFieldAccessor(
    searchParamAccessor,
    "edit"
  );

  return (
    <>
      <section className="hero">
        <div className="hero-body">
          {parameter.header?.isStacked || parameter?.clipboard ? (
            <Stack direction="horizontal" gap={3}>
              <h1 className="title">{title}</h1>
              <h2 className="subtitle">{subtitle}</h2>

              <div className="ms-auto">
                {/* {editMode && parameter.remove && (
                  <Button
                    style={{ marginLeft: "5px" }}
                    variant="danger"
                    onClick={parameter.remove?.handler}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="削除"
                  >
                    <i className="mdi mdi-delete"></i>
                  </Button>
                )} */}
                {!loading &&
                  parameter.clipboard &&
                  !parameter.clipboard.isDisabled && (
                    <Button
                      style={{ marginLeft: "5px" }}
                      onClick={async () => {
                        copy(await accessorRef.current?.get());
                      }}
                      variant="outline-secondary"
                      data-tooltip-id="tooltip"
                      data-tooltip-content="コピー"
                    >
                      <i className="mdi mdi-content-copy" />
                    </Button>
                  )}
                {!loading &&
                  parameter.clipboard &&
                  !parameter.clipboard.isDisabled &&
                  ((editModeEnabled && editMode) || !editModeEnabled) && (
                    <>
                      <Button
                        style={{ marginLeft: "5px" }}
                        onClick={async () => {
                          const currentValue = accessorRef.current?.get();
                          const retainedValue = Object.fromEntries(
                            parameter.clipboard?.retainKeys?.map((key) => [
                              key,
                              currentValue?.[key],
                            ]) || []
                          );
                          const clipBoardData = await paste();
                          if (!clipBoardData) return;
                          accessorRef.current?.set({
                            ...clipBoardData,
                            ...retainedValue,
                          });
                        }}
                        variant="outline-secondary"
                        data-tooltip-id="tooltip"
                        data-tooltip-content="貼り付け"
                      >
                        <i className="mdi mdi-content-paste" />
                      </Button>
                    </>
                  )}
                {editModeEnabled && editMode && (
                  <Button
                    style={{ marginLeft: "5px" }}
                    variant="secondary"
                    onClick={async () => {
                      const result = await confirm({
                        message: "破棄しますか？",
                      });
                      if (!result) return;
                      accessorRef.current?.set(data);
                      setEditMode(undefined);
                    }}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="破棄して閲覧画面に戻る"
                  >
                    <i className="mdi mdi-window-close"></i>
                  </Button>
                )}
                {editModeEnabled &&
                  !editMode &&
                  parameter.edit &&
                  !parameter.edit.isDisabled && (
                    <Button
                      style={{ marginLeft: "5px" }}
                      variant="secondary"
                      onClick={async () => {
                        setEditMode("1");
                      }}
                      data-tooltip-id="tooltip"
                      data-tooltip-content="編集"
                    >
                      <i className="mdi mdi-pencil"></i>
                    </Button>
                  )}
                {!loading && parameter.tooltip && (
                  <>
                    {" "}
                    <Button
                      variant="secondary"
                      data-tooltip-id="tooltip-click"
                      data-tooltip-html={
                        parameter.tooltip &&
                        `<div class="tooltip-content">${parameter.tooltip}</div>`
                      }
                      style={{
                        borderRadius: "50%",
                        width: "26px",
                        height: "26px",
                        padding: "0",
                      }}
                      // size="sm"
                    >
                      <span className="mdi mdi-information-variant"></span>
                    </Button>
                  </>
                )}
              </div>
            </Stack>
          ) : (
            <>
              <h1 className="title">{title}</h1>
              <h2 className="subtitle">{subtitle}</h2>
            </>
          )}
        </div>
      </section>
      <div style={{ padding: "0 18px" }}>{header}</div>

      <section>
        {loading ? (
          <div style={{ padding: "20px" }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <SchemaForm
            data={data}
            parameter={parameter}
            readOnly={readOnly || (editModeEnabled && !editMode)}
            accessorRef={accessorRef}
          />
        )}
      </section>
    </>
  );
};

export default SchemaFormPage;
