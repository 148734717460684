import { StringIntl, DateRange } from "./type";
import { defineCollection } from "../comodel-firestore";

export type shopData = {
  shopId: string;
  clientId: string;
  createdAt: number;
  shopName: StringIntl;
  logoURL: string;
  showShopNameInHeader: boolean;
  surveyId: string;
  rewardCondition?: {
    rewardId: string;
    probability: number;
    animationId: number;
  }[];
  shopSignature: StringIntl;
  shopSender: StringIntl;
  entryTitle: StringIntl;
  entrySubtitle: StringIntl;
  enqueteStartAt: number;
  enqueteEndAt: number;
  region: string;
  isAuthSkipped: boolean;
  enqueteCompleteLinks: {
    type: "tripAdvisor" | "googleMap" | "tabelog";
    url: string;
  }[];
  enqueteCompletedText?: StringIntl;
  ignore?: boolean;
};

export const shopDefinition = defineCollection({
  collectionName: "shop",
  idKey: "shopId",
  constraints: ["clientId"],
  queryDefs: {
    list: ({
      clientId,
      createdAt,
      condition,
    }: {
      clientId?: string;
      createdAt?: DateRange;
      condition?: Record<string, unknown>;
    }) => {
      return {
        constraint: clientId ? { clientId } : undefined,
        queryParams: {
          filter: { clientId, createdAt },
          orderBy: [["createdAt", "desc"]],
        },
        filterParams: condition,
      };
    },
  },
  defaultValue: {} as shopData,
  onGenerate: (o) => ({ ...o, createdAt: Date.now() }),
  onWrite: (o) => ({ ...o, updatedAt: Date.now() }),
});
